/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-07-09",
    versionChannel: "nightly",
    buildDate: "2024-07-09T00:05:50.583Z",
    commitHash: "eec5e908adb9c8809865dd534fbfff8116a43bdb",
};
